import React from "react";

export default function ErrorFallback() {
  return (
    <div role="alert">
      <h3>Something went wrong</h3>
      <p>We are sending you to home page.</p>
    </div>
  );
}
