import { useState, useEffect, React, useContext } from 'react';
import BookingCard from "../Bookings/BookingCard"
import { Row } from 'react-bootstrap';
import AppContext from '../Data/AppContext';
import useGetBookings from '../../services/useGetBookings';
import BookingDialog from '../Dialog/BookingDialog'
import { useHistory } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';

const Bookings = (props) => {
  const history = useHistory();
  const glContext = useContext(AppContext);
  if (!glContext.Loggedin){
    history.push("/Home");
  }
  const [activeBooking,setActiveBooking]= useState(null);
  const [showBookingsDialog, setShowBookingsDialog] = useState(false);
  var rest = localStorage.getItem("restaurant");
  var currentRestaurant = JSON.parse(rest);
  const { bookingsList, doFetch } = useGetBookings("");
  

  const gotoLoginPage=()=>{
    history.push("/manage"); 
  }

  const openBookingForm=()=>{
    setActiveBooking(null);
    setShowBookingsDialog(true);
  }

  const openBooking=()=>{
    setShowBookingsDialog(true);
  }

  const refetchBookings=()=>{
    if(glContext.Loggedin && glContext.RestaurantId){
      NotificationManager.success('Finding Bookings', null, 500);
      let r = Math.random().toString(36).substring(7);
      let url= process.env.REACT_APP_GET_BOOKINGS + glContext.RestaurantId;
      doFetch(url, r);
      //props.refetchOnlineOrders();
    }else{
      let rest = localStorage.getItem("restaurant");
      if(rest){
        let currentRestaurant = JSON.parse(rest);
        let r = Math.random().toString(36).substring(7);
        let url= process.env.REACT_APP_GET_BOOKINGS + currentRestaurant.id;
        glContext.setRestaurantId(currentRestaurant.id);
        doFetch(url, r);
      }
      
    }
  }

  useEffect(() => {
    try {
      refetchBookings();
      const interval = setInterval(() => {
        refetchBookings();
      }, 1200000);

      return () => clearInterval(interval);
    } catch {

    }
  },[glContext.RestaurantId]);

  return (
    <Row>
      <NotificationContainer />      
      <div className="col-md-8 text-left margin-top-10 bookingContainer" id="bookingList"  >
      {glContext.Loggedin &&
      <div className="margin-bottom-10">
        <button className="btn btn-lg btn-primary form-control" onClick={openBookingForm} >Add New Booking</button>
      </div>}
      {showBookingsDialog && <BookingDialog refetchBookings={refetchBookings} activeBooking={activeBooking}  setShowBookingsDialog={setShowBookingsDialog} />}
          
        {(bookingsList && bookingsList.length === 0 && glContext.Loggedin ) && 
          <div>
            <div className="col-md-12 text-left margin-top-10" >
              {currentRestaurant && <h2>{currentRestaurant.name} EPOS</h2>}
              {currentRestaurant && <h5>No reservations</h5>}
              {!currentRestaurant && <h5>Please select a restaurant <button className="btn btn-sm btn-primary settingsBtn" onClick={gotoLoginPage}>here</button></h5>}
            </div>
          </div>
        }
        {glContext.Loggedin &&
          Object.entries(bookingsList)
            .map(([key, value]) =>
            <BookingCard openBooking={openBooking} setActiveBooking={setActiveBooking} refreshOrders={props.refreshBookings} key={value.id} data={value} />)
        }
      </div>    
    </Row>

  );
}

export default Bookings;
