import { React, useContext, useState } from 'react';
import "./OrderDetailsItems.css";
import { Button } from 'react-bootstrap';
import AppContext from '../../Data/AppContext';
import NotesDialog from '../../Dialog/NotesDialog';
import SetMealSelectionDialog from '../../Dialog/SetMealSelectionDialog';
const OrdersDetailsItems = (props) => {
   
    const glContext = useContext(AppContext);
    const restaurant = glContext.Restaurant;
    var set_meals = restaurant.set_meals;
    const { settotalItems } = props;
    const itemKey = props.itemKey;
    const [notes, setNotes] = useState(props.data.note);
    const [showNotesDialog, setShowNotesDialog] = useState(false);
    const activeOrder = props.activeOrder;
    const {setActiveOrder} = props;
    const {setItems}= props;
    const { setRefresh } = props;
    const [showSetMealDialog, setShowSetMealDialog]=useState(false);
    const [selectedSetMeal, setSelectedSetMeal] = useState(0);
    const onlineOrder = (activeOrder.origin === 'online');
    let isSetMeal = false;
    let modifiedNotes =notes;
    let noteIsObj='';
    let setMeal=[];
    if(IsJsonString(notes)){
        try {
            noteIsObj = JSON.parse(notes);
        }catch (e){}
         
        if (noteIsObj !== false){
            for (const group in noteIsObj) {
            let items = noteIsObj[group];
            items.forEach(item => {
                    setMeal.push('- ' + item['name']
                    + (item['extra_price'] > 0 ?  (" ("+ activeOrder.currency_symbol + parseFloat(item['extra_price']).toFixed(2) + ")") : '') );
            });
            }
            if(setMeal.length>0){
            isSetMeal= true;
            }
        }
    }
    let name = "";
    if (props.data.item_sub_type === "" || props.data.item_sub_type === null) {
        name = props.data.name;
    } else {
        if(setMeal.length === 0 && !onlineOrder){ 
            // For setmeal we dont need to show the subtype which is setMeal
            name = props.data.name + " (" + props.data.item_sub_type + ")";
        }else{
            name = props.data.name;
        }
    }
   
 
    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    const refreshNotes=(note)=>{
        setNotes(note);
    }
  const removeSetMealButtonClick =(e)=>{
      delete activeOrder.order_lines.items[e];
      setActiveOrder(activeOrder);
      glContext.setActiveOrder(activeOrder);
      glContext.setDirty(true);
      settotalItems(Math.random());
  }
    const removeButtonClick = (e) => {
        if (props.data.item_sub_type === null) {
            Object.entries(activeOrder.order_lines.items)
            .map(([key, value]) => {
                if (value.item_id === e.target.id) {
                    let q = value.quantity;
                    let quantity = parseInt(q);
                    quantity = quantity - 1;
                    if (quantity === 0) {
                        delete activeOrder.order_lines.items[key];
                        setActiveOrder(activeOrder);
                        glContext.setActiveOrder(activeOrder);
                        glContext.setDirty(true);
                        settotalItems(Math.random());
                    } else {
                        value.quantity = quantity;
                        let amount = parseFloat(value.price) * parseFloat(value.quantity);
                        value.amount = parseFloat(amount).toFixed(2);
                        value.price = parseFloat(value.price).toFixed(2);
                        activeOrder.order_lines.items[key] = value;
                        setActiveOrder(activeOrder);
                        setItems();
                        glContext.setActiveOrder(activeOrder);
                        settotalItems(Math.random());
                        glContext.setDirty(true);
                    }
                    setRefresh(!props.refresh);
                }
            }
            ) 
        }else {
            Object.entries(activeOrder.order_lines.items)
            .map(([key, value]) => {
                var lclName= value.name + " (" + value.item_sub_type + ")";
                if (value.item_id === e.target.id && lclName === name) {
                    let q = value.quantity;
                    let quantity = parseInt(q);
                    quantity = quantity - 1;
                    if (quantity === 0) {
                        delete activeOrder.order_lines.items[key];
                        setActiveOrder(activeOrder);
                        glContext.setActiveOrder(activeOrder);
                        glContext.setDirty(true);
                        settotalItems(Math.random());
                    } else {
                        value.quantity = quantity;
                        let amount = parseFloat(value.price) * parseFloat(value.quantity);
                        value.amount = parseFloat(amount).toFixed(2);
                        value.price = parseFloat(value.price).toFixed(2);
                        activeOrder.order_lines.items[key] = value;
                        setActiveOrder(activeOrder);
                        setItems();
                        glContext.setActiveOrder(activeOrder);
                        settotalItems(Math.random());
                        glContext.setDirty(true);
                    }
                  
                }
            }
            )
        }
        setRefresh(!props.refresh);
    }

    const addButtonClick = (e) => {
        if (props.data.item_sub_type === null) {
            Object.entries(activeOrder.order_lines.items)
            .map(([key, value]) => {
                if (value.item_id === e.target.id) {
                    let q = value.quantity;
                    let quantity = parseInt(q);
                    quantity = quantity + 1;
                    value.quantity = quantity;
                    let amount = parseFloat(value.price) * parseFloat(value.quantity);
                    value.amount = parseFloat(amount).toFixed(2);
                    activeOrder.order_lines.items[key] = value;
                    setActiveOrder(activeOrder);
                    glContext.setActiveOrder(activeOrder);
                    settotalItems(Math.random());
                    glContext.setDirty(true);
                }
            }
            )
        } else {
            Object.entries(activeOrder.order_lines.items)
            .map(([key, value]) => {
                var lclName= value.name + " (" + value.item_sub_type + ")";
               
                if (value.item_id === e.target.id && lclName === name) {
                    let q = value.quantity;
                    let quantity = parseInt(q);
                    quantity = quantity + 1;
                    value.quantity = quantity;
                    let amount = parseFloat(value.price) * parseFloat(value.quantity);
                    value.amount = parseFloat(amount).toFixed(2);
                    activeOrder.order_lines.items[key] = value;
                    setActiveOrder(activeOrder);
                    glContext.setActiveOrder(activeOrder);
                    glContext.setDirty(true);
                    settotalItems(Math.random());
                }
            }
            )
        }
        setRefresh(!props.refresh);
    }

    const handleNotesOpen = () => {
        if (!onlineOrder) {
            setShowNotesDialog(true);
        }
    };

    const handleSetMealDialogOpen= ()=>{
        if (!onlineOrder) {
            var itm;
            Object.entries(set_meals.items).map(([key, value]) => {
                    if(value.id==props.data.item_id){
                        itm = key;
                    }
            });
            setSelectedSetMeal(itm);
            setShowSetMealDialog(true);
        }
    }

    if (onlineOrder && props.data.amount === null) {
        props.data.amount = props.data.price * props.data.quantity;
        props.data.amount = props.data.amount.toFixed(2);
    }

    return (
        <div>
            {showNotesDialog && <NotesDialog showNotesDialog={showNotesDialog} itemKey={itemKey} item_id={props.data.item_id} setShowNotesDialog={setShowNotesDialog} refreshNotes={refreshNotes} notes={notes} />}
            {showSetMealDialog && <SetMealSelectionDialog  showSetMealDialog={showSetMealDialog} id={selectedSetMeal} notes ={notes} data={props.data} items={setMeal.items} refreshNotes={refreshNotes}  setShowSetMealDialog={setShowSetMealDialog}  />}
            <div className="OrdersDetailsItemsContainer row"  id={props.data.id}  >
                {!onlineOrder && 
                <div className="col-lg-3 col-md-4 d-none d-md-block">
                    <div className="btn-group">
                       <Button hidden={isSetMeal} id={props.data.item_id} onClick={addButtonClick} className="btn btn-success form-control">+</Button>
                       {!isSetMeal &&<Button hidden={isSetMeal} id={props.data.item_id} onClick={removeButtonClick} className="btn btn-light form-control">-</Button>}
                       {isSetMeal&&  <Button  id={props.data.id} onClick={(e)=>removeSetMealButtonClick(props.data.id)} className="btn btn-light form-control">-</Button>}
                    </div>
                </div>}
                { (setMeal.length ===0) &&
                     <div className="col-lg-7 col-md-6 col-sm-8 col-xs-3 text-left clickable" onClick={handleNotesOpen}>
                     {props.data.quantity} x {name}
                     <br/><i className="" onClick={handleNotesOpen}>{modifiedNotes}</i>
                     </div>
                }
                { (setMeal.length >0) &&
                    <div className="col-lg-7 col-md-6 col-sm-8 col-xs-3 text-left clickable"  onClick={handleSetMealDialogOpen}>
                    {props.data.quantity} x {name} 
                    <br/><i className="" > {
                        <ul className="list-unstyled">
                            {setMeal.map((value , i) => {
                               return <li key={i} > {value} </li>
                            })
                            }
                        </ul>
                    }</i>
                    </div>
                }
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-3 text-right">{props.currency}{props.data.amount}</div>
            </div>
        </div>

    );
}

export default OrdersDetailsItems;
