// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* make a video player */\n.TutorialDialog_player__3rlFS{\n    position: relative;\n    height: 450px;\n    width: 70%;\n    overflow: hidden;\n    max-width: 100%;\n    margin: 0 auto;\n}\n\n.TutorialDialog_loading__2fmA-{\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    background: rgba(0,0,0,0.2);\n    z-index: 2;\n    animation: TutorialDialog_animate__2az5I 1s linear infinite;\n}\n\n@keyframes TutorialDialog_animate__2az5I {\n    0% {\n        opacity: 0;\n    } 50% {\n        opacity: 1;\n    } 100% {\n        opacity: 0;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Dialog/TutorialDialog.module.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,YAAY;IACZ,WAAW;IACX,2BAA2B;IAC3B,UAAU;IACV,2DAAqC;AACzC;;AAEA;IACI;QACI,UAAU;IACd,EAAE;QACE,UAAU;IACd,EAAE;QACE,UAAU;IACd;AACJ","sourcesContent":["/* make a video player */\n.player{\n    position: relative;\n    height: 450px;\n    width: 70%;\n    overflow: hidden;\n    max-width: 100%;\n    margin: 0 auto;\n}\n\n.loading{\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    background: rgba(0,0,0,0.2);\n    z-index: 2;\n    animation: animate 1s linear infinite;\n}\n\n@keyframes animate {\n    0% {\n        opacity: 0;\n    } 50% {\n        opacity: 1;\n    } 100% {\n        opacity: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"player": "TutorialDialog_player__3rlFS",
	"loading": "TutorialDialog_loading__2fmA-",
	"animate": "TutorialDialog_animate__2az5I"
};
export default ___CSS_LOADER_EXPORT___;
