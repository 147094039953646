import { useContext, React, useState } from 'react';
import "./Menu.css"
import { Button } from 'react-bootstrap';
import AppContext from '../Data/AppContext'
import { useHistory } from "react-router-dom";
import SetMealSelectionDialog from '../Dialog/SetMealSelectionDialog';
import AddMenuDialog from '../Dialog/AddMenuDialog';
const Menu = (props) => {
  const history = useHistory();
  const glContext = useContext(AppContext);
  const restaurant = glContext.Restaurant;
  const newMenuUrl = process.env.REACT_APP_NEW_MENU + glContext.RestaurantId;
  var lmenu = restaurant.menu;
  var set_meals = restaurant.set_meals;
  glContext.setSetMeal(set_meals); 
  if (glContext.ActiveOrder.order_type === 'eat-in' 
    && restaurant.menu_eatin.categories
    && restaurant.menu_eatin.categories.items
    && Object.keys(restaurant.menu_eatin.categories.items).length > 0) {
    lmenu = restaurant.menu_eatin;
  }
  const [menu,setMenu] = useState(lmenu);
  const [setMeal,setSetMeal] = useState(set_meals);
  const [categoryFoodItems, setCategoryFoodItems] = useState({});
  const [showMenuDialog, setShowMenuDialog]=useState(false);
  const [showSetMealDialog, setShowSetMealDialog]=useState(false);
  const [selectedFoodCategory, setSelectedFoodCategory] = useState({});
  const [selectedFoodItem, setSelectedFoodItem] = useState({});
  const [selectedSetMeal, setSelectedSetMeal] = useState(0);
  const [categoryId, setCategoryId] = useState(0);
  const [classicItems, setclassicItems] = useState({});
  const { setItems } = props;
  const { settotalItems } = props;
  const { setRefresh } = props;
  const activeOrder = props.activeOrder;
  const {setActiveOrder} = props;
  //let classicCategory = "btn-lg classicCategory";
  if (restaurant === {} || menu === undefined) {
    history.push("/");  // if the restaurant object is not loaded. it will be redicected to home
  } else {
    //setCategoryList(restaurant.menu.categories.items);
  }
  
  const refreshMenu = (newRestaurant)=>{
    var lmenu = newRestaurant.menu;
    if (glContext.ActiveOrder.order_type === 'eat-in' 
      && newRestaurant.menu_eatin.categories
      && newRestaurant.menu_eatin.categories.items
      && Object.keys(newRestaurant.menu_eatin.categories.items).length > 0) {
      lmenu = newRestaurant.menu_eatin;
    }
    setMenu(lmenu);
    setCategoryFoodItems([]);
  }
  
  function handleClassicItemClick(e) {
    pushSelectedFoodItemtoOrder(e,selectedFoodItem);
  }

  function handlefoodItemClick(e) {
    var itm = menu.categories.items[categoryId].food_items.items[e.target.id];
    let category = menu.categories.items[categoryId];
    setSelectedFoodItem(itm);

    //var properties = Object.getOwnPropertyNames(menu.categories.items[categoryId].food_items.items[e.target.id]);
    //var prices = properties.filter(function (item) {return (item.includes("price")) });
    var classicCollection = [];
    if (category.type==='classic') // will be greaater for classic items
    {
      if (parseFloat(itm.price) > 0 > 0) {
        classicCollection.push({
          key: itm.id + "_chicken", 
          item_sub_type: "chicken", 
          name: (category.chicken_header !== null ? category.chicken_header : 'Chicken'), 
          value: parseFloat(itm.price).toFixed(2)
        });
      }
      if (category.show_beef_price === "1" && parseFloat(itm.price_beef) > 0) {
        classicCollection.push({
          key: itm.id + "_beef", 
          item_sub_type: "beef", 
          name: (category.beef_header !== null ? category.beef_header : 'Beef'), 
          value: parseFloat(itm.price_beef).toFixed(2)
        });
      } 
      if (category.show_lamb_price === "1" && parseFloat(itm.price_lamb) > 0) {
        classicCollection.push({
          key: itm.id + "_lamb", 
          item_sub_type: "lamb", 
          name: (category.lamb_header !== null ? category.lamb_header : 'Lamb'), 
          value: parseFloat(itm.price_lamb).toFixed(2)
        });
      } 
      if (category.show_chicken_tikka_price === "1" && parseFloat(itm.price_chicken_tikka) > 0) {
        classicCollection.push({
          key: itm.id + "_chicken_tikka", 
          item_sub_type: "chicken_tikka",           
          name: (category.chicken_tikka_header !== null ? category.chicken_tikka_header : 'Chicken Tk.'), 
          value: parseFloat(itm.price_chicken_tikka).toFixed(2)
        });
      } 
      if (category.show_lamb_tikka_price === "1" && parseFloat(itm.price_lamb_tikka) > 0) {
        classicCollection.push({
          key: itm.id + "_lamb_tikka", 
          item_sub_type: "lamb_tikka", 
          name: (category.lamb_tikka_header !== null ? category.lamb_tikka_header : 'Lamb Tk.'), 
          value: parseFloat(itm.price_lamb_tikka).toFixed(2)
        });
      } 
      if (category.show_chicken_lamb_tikka_price === "1" && parseFloat(itm.price_chicken_lamb_tikka) > 0) {
        classicCollection.push({
          key: itm.id + "_chicken_lamb_tikka", 
          item_sub_type: "chicken_lamb_tikka", 
          name: (category.chicken_lamb_tikka_header !== null ? category.chicken_lamb_tikka_header : 'Chicken Tk. + Lamb Tk.'), 
          value: parseFloat(itm.price_chicken_lamb_tikka).toFixed(2)
        });
      }  
      if (category.show_duck_price === "1" && parseFloat(itm.price_duck) > 0) {
        classicCollection.push({
          key: itm.id + "_duck", 
          item_sub_type: "duck", 
          name: (category.duck_header !== null ? category.duck_header : 'Duck'), 
          value: parseFloat(itm.price_duck).toFixed(2)
        });
      }  
      if (category.show_duck_tikka_price === "1" && parseFloat(itm.price_duck_tikka) > 0) {
        classicCollection.push({
          key: itm.id + "_duck_tikka", 
          item_sub_type: "duck_tikka", 
          name: (category.duck_tikka_header !== null ? category.duck_tikka_header : 'Duck Tk.'), 
          value: parseFloat(itm.price_duck_tikka).toFixed(2)
        });
      }  
      if (category.show_prawn_price === "1" && parseFloat(itm.price_prawn) > 0) {
        classicCollection.push({
          key: itm.id + "_prawn", 
          item_sub_type: "prawn",
          name: (category.prawn_header !== null ? category.prawn_header : 'Prawn'), 
          value: parseFloat(itm.price_prawn).toFixed(2)
        });
      }  
      if (category.show_king_prawn_price === "1" && parseFloat(itm.price_king_prawn) > 0) {
        classicCollection.push({
          key: itm.id + "_king_prawn", 
          item_sub_type: "king_prawn", 
          name: (category.king_prawn_header !== null ? category.king_prawn_header : 'King Prawn'), 
          value: parseFloat(itm.price_king_prawn).toFixed(2)
        });
      } 
      if (category.show_tandoori_king_prawn_price === "1" && parseFloat(itm.price_tandoori_king_prawn) > 0) {
        classicCollection.push({
          key: itm.id + "_tandoori_king_prawn", 
          item_sub_type: "tandoori_king_prawn", 
          name: (category.tandoori_king_prawn_header !== null ? category.tandoori_king_prawn_header : 'Tandoori King Pr.'), 
          value: parseFloat(itm.price_tandoori_king_prawn).toFixed(2)
        });
      } 
      if (category.show_mixed_price === "1" && parseFloat(itm.price_mixed) > 0) {
        classicCollection.push({
          key: itm.id + "_mixed", 
          item_sub_type: "mixed",
          name: (category.mixed_header !== null ? category.mixed_header : 'Mixed'), 
          value: parseFloat(itm.price_mixed).toFixed(2)
        });
      } 
      if (category.show_vegetable_price === "1" && parseFloat(itm.price_vegetable) > 0) {
        classicCollection.push({
          key: itm.id + "_vegetable", 
          item_sub_type: "vegetable", 
          name: (category.vegetable_header !== null ? category.vegetable_header : 'Vegetable'), 
          value: parseFloat(itm.price_vegetable).toFixed(2)
        });
      } 

      setclassicItems(classicCollection);
    }
    else {
        pushSelectedFoodItemtoOrder(null,itm);
    }
  }

  const createNewOrderLine = (selectedSubType,itm) => {
    let price = parseFloat(itm.price).toFixed(2);
    var newOrderLine = {
      'quantity': 1,
      'order_id': activeOrder.id,
      'category_id': itm.category_id,
      'category_priority': selectedFoodCategory.category_priority,
      'id': 0,
      'item_id': itm.id,
      'name': itm.name,
      'item_sub_type': null,
      'price': price,
      'amount': price, // as quantity = 1
      'topups': null,
      'note': null,
      'ts': new Date(),
    };
   
    if (null !== selectedSubType 
      && selectedSubType.name !== undefined 
      && selectedSubType.item_sub_type !== undefined
      && selectedSubType.value !== undefined) {
       // newOrderLine.name += ' (' + selectedSubType.name + ')';
        newOrderLine.item_sub_type = selectedSubType.name; 
        newOrderLine['price'] = parseFloat(selectedSubType.value).toFixed(2);
        newOrderLine['amount'] = parseFloat(selectedSubType.value).toFixed(2);
    }
    return newOrderLine;
  }

  const pushSelectedFoodItemtoOrder = (selectedSubType, itm) => {
    var actOrd = activeOrder;
    var tmpOrderLines = activeOrder.order_lines.items;
    if (Object.entries(actOrd.order_lines.items).length === 0) {
      tmpOrderLines = {};
    }
    var newOrderLine = createNewOrderLine(selectedSubType, itm);
      var matchFound = false;
      if (selectedFoodCategory.topups !== null && selectedFoodCategory.topups !== undefined) {
        /*
        // TODO - add the new order line to order - completely a new line
        // TODO - open topup dialog 
        
        matchFound = false;
      */
      } else {
        for(const [key, line] of Object.entries(tmpOrderLines)){
          if(line.item_id === newOrderLine.item_id 
            && line.item_sub_type === newOrderLine.item_sub_type){
              matchFound = key;
              break;
            }
        }
      }
  
      if (matchFound) {
        let existingLine = tmpOrderLines[matchFound];
        existingLine.quantity++;
        existingLine.amount = parseFloat(existingLine.price * existingLine.quantity).toFixed(2);
        tmpOrderLines[matchFound] = existingLine;
      } else {
        tmpOrderLines[makeid(4)] = newOrderLine;
      }
      
    actOrd.order_lines.items = tmpOrderLines;
    settotalItems(Object.entries(tmpOrderLines).length);
    setActiveOrder(actOrd);
    glContext.setActiveOrder(actOrd);
    setItems(actOrd.order_lines.items);
    glContext.setDirty(true);
    setRefresh(!props.refresh);
  }

  function handleNewfoodItemClick(){
    setShowMenuDialog(true);
  }

  function handleCategoryClick(value) {
    setclassicItems({});
    setCategoryId(value.id);
    setSelectedFoodCategory(menu.categories.items[value.id]);
    if (menu.categories.items[value.id].food_items.items !== undefined) {
      setCategoryFoodItems(menu.categories.items[value.id].food_items.items);
    }
  }

  function handleSetMealClick(key){
    setSelectedSetMeal(key)
    setShowSetMealDialog(true);
  }

  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  return (
    <div className="menuDetailsContainer">
      {showSetMealDialog && <SetMealSelectionDialog showSetMealDialog={showSetMealDialog} items={setMeal.items} id={selectedSetMeal}  setShowSetMealDialog={setShowSetMealDialog}  />}
       {showMenuDialog && <AddMenuDialog showMenuDialog={showMenuDialog} newMenuUrl={newMenuUrl}  setShowMenuDialog={setShowMenuDialog} categoryId={categoryId} refreshMenu={refreshMenu} />}
        <div className="row orderDetailsLevel1">
        {(restaurant !== {} && setMeal !== undefined) &&
            Object.entries(setMeal.items)
              .map(([key, value]) =>{
               // if (value.length > 0 ) //value.status == 'published'
                  return   <Button type="button" key={key} id={value.id} onClick ={() => handleSetMealClick(key)}  className={"btn btn-secondary btn-lg item classicItem"}>{value.name}</Button>
                }
              )
          }
          {(restaurant !== {} && menu !== undefined) &&
            Object.entries(menu.categories.items)
              .map(([key, value]) =>{
                if (value.food_items.items.length > 0 && value.status==="95fded8124e8426212aa8cddba37a2d02ef7") //value.status == 'published'
                  return   <Button type="button" key={key} id={value.id} onClick={() => handleCategoryClick(value)} className={"btn-lg " + value.type}>{value.name}</Button>
                }
              )
          }
        </div>
        <div className="row orderDetailsLevel2">
          {
            Object.entries(categoryFoodItems)
              .map(([key, value]) =>
                <Button type="button" key={key} id={key} onClick={handlefoodItemClick} className="btn btn-lg foodItem">{value.name} {(value.price > 0 ? glContext.ActiveOrder.currency_symbol + value.price: '')}</Button>
              )
          }
          {(1 !== 1 && categoryId!== 0 && menu.categories.items[categoryId].type !== 'classic') &&
             <Button type="button" id="NewItemButton" onClick={handleNewfoodItemClick} className="btn btn-light btn-lg">Add New</Button>
          }
        </div>
        <div className="row orderDetailsLevel3">
          {
            Object.entries(classicItems)
              .map(([key, value]) =>
              (
                <Button type="button" key={key} id={value.key} onClick={()=>handleClassicItemClick(value)} className="btn btn-secondary btn-lg item classicItem">{value.name}</Button>
              )
            )
          }
        </div>
    </div>
  );
}
export default Menu;
