// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tutorials_tutorials__QWLiV{\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    grid-gap: 10px;\n    gap: 10px;\n    margin: 0 30px;\n    padding-bottom: 30px;\n}", "",{"version":3,"sources":["webpack://src/components/Manage/Tutorials.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,cAAS;IAAT,SAAS;IACT,cAAc;IACd,oBAAoB;AACxB","sourcesContent":[".tutorials{\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    gap: 10px;\n    margin: 0 30px;\n    padding-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tutorials": "Tutorials_tutorials__QWLiV"
};
export default ___CSS_LOADER_EXPORT___;
